import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';

import { map } from 'rxjs';

import { LaunchDarklyService } from 'src/app/services/launchdarkly.service';
import { UnderMaintenanceComponent } from 'src/app/components/under-maintenance/under-maintenance.component';

import { environment } from './../environments/environment';
import { HeaderComponent } from './components/header/header.component';

/**
 * App component.
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [HeaderComponent, RouterOutlet, UnderMaintenanceComponent, AsyncPipe],
})
export class AppComponent {
	public underMaintenance$ = this.ldService.launchDarklyFlags$.pipe(
		map(flags => {
			if (environment.environment === 'hero') {
				return flags['hero-under-maintenance'];
			} else {
				return flags['under-maintenance'];
			}
		}),
	);

	/**
	 * Constructor.
	 * @param ldService To get maintenance status.
	 */
	constructor(private ldService: LaunchDarklyService) {}
}
