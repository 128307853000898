import { gql } from 'apollo-angular';

export class loginMutations {
	public static openSession = gql`
		mutation openSession($code: String!, $authProvider: String) {
			openSession(code: $code, authProvider: $authProvider) {
				RedirectUrl
				SessionId
			}
		}
	`;
}
