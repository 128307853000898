import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { map } from 'rxjs';

import { ButtonModule } from 'primeng/button';

import { AppConfigService } from 'src/app/services/app-config.service';
import { AuthService } from 'src/app/services/auth.service';
import { LaunchDarklyService } from 'src/app/services/launchdarkly.service';
import { SessionService } from 'src/app/services/session.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
	imports: [AsyncPipe, ButtonModule, RouterLink],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class NavigationComponent {
	environment = environment.environment;

	ddbUrl: string;

	configUrl: string;

	executorUrl: string;

	resultsUrl: string;

	reportsUrl: string;

	vehicleBookingsUrl: string;

	public validatdSessionStatus = this.sessionService.validateSession$();

	public isUnderDevelopment$ = this.launchDarklyService.launchDarklyFlags$.pipe(map(flags => flags['under-development']));

	constructor(
		public appConfigService: AppConfigService,
		public authService: AuthService,
		private sessionService: SessionService,
		private utilityService: UtilsService,
		private launchDarklyService: LaunchDarklyService,
	) {
		this.ddbUrl = this.appConfigService.getDdbUrl();
		this.configUrl = this.appConfigService.getConfigUrl();
		this.executorUrl = this.appConfigService.getExecutorUrl();
		this.resultsUrl = this.appConfigService.getResultsUrl();
		this.reportsUrl = this.appConfigService.getReportsUrl();
		this.vehicleBookingsUrl = this.appConfigService.getVehicleBookingsUrl();
	}
}
