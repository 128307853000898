@if (!isEditing) {
	<div class="release-note-item">
		- {{ releaseNote.IssueTitle }}
		@if ((isAdmin$ | async) && editable) {
			<p-button [text]="true" icon="pi pi-pencil" severity="secondary" (onClick)="isEditing = true" />
		}
	</div>
} @else {
	<form [formGroup]="releaseNotesFormGroup">
		<div class="release-note-item">
			<div class="controls-container">
				<input formControlName="releaseNoteTitle" />
				<div class="hidden-switch-container">
					<p-inputSwitch formControlName="releaseNoteHidden" />
					Hidden
				</div>
			</div>
			<p-button [text]="true" icon="pi pi-save" severity="success" (onClick)="saveReleaseNote()" />
			<p-button [text]="true" icon="pi pi-times" severity="secondary" (onClick)="isEditing = false" />
		</div>
	</form>
}
