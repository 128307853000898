import { gql } from 'apollo-angular';

export class releaseNotesQueries {
	public static releaseNotesGetAllStaging = gql`
		query releaseNotesGetAllStaging {
			releaseNotesGetAllStaging {
				CommitHash
				CommitMessage
				CommitDescription
				CommitTitle
				CommitTimestamp
				ProjectTitle
				ProjectName
				IssueKey
				IssueTitle
				IssueLabels
				ReleaseTime
			}
		}
	`;

	public static releaseNotesGetAllRelease = gql`
		query releaseNotesGetAllRelease {
			releaseNotesGetAllRelease {
				CommitHash
				CommitMessage
				CommitDescription
				CommitTitle
				CommitTimestamp
				ProjectTitle
				ProjectName
				IssueKey
				IssueTitle
				IssueLabels
				ReleaseTime
			}
		}
	`;
}
