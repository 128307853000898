<div class="main-container">
	<div class="header-container">
		<h1>WELCOME TO TEST TOOLS</h1>
		<h4>Test tools is a collection of application which allows for a great testing experience.</h4>
		@if ((authService.isLoggedIn$ | async) === false) {
			<div>Please sign in to access our applications.</div>

			<p-button label="Login" routerLink="/login"></p-button>
		}
	</div>

	<div class="main-content-container">
		@if (authService.isLoggedIn$ | async) {
			<div class="link-items-container">
				<a [href]="configUrl" class="link-item">
					<div>
						<div class="header">
							<i class="configurator"></i>
							Configurator
						</div>
						<div class="description">Create and configure Test Analysis, Test Cases and Test Suites</div>
					</div>
				</a>

				<a [href]="executorUrl" class="link-item">
					<div>
						<div class="header">
							<i class="executor"></i>
							Executor
						</div>
						<div class="description">Execute test suites and create test runs</div>
					</div>
				</a>

				<a [href]="resultsUrl" class="link-item">
					<div>
						<div class="header">
							<i class="results"></i>
							Results
						</div>
						<div class="description">View the results</div>
					</div>
				</a>

				@if (isUnderDevelopment$ | async) {
					<div class="link-item" onclick="location.href='new_url2.html';">
						<div class="header">
							<i class="sopsevaluator"></i>
							SOPS Evaluator
						</div>
						<div class="description">PreProd "TCR/VCR" functional SOP</div>
					</div>
				}

				<a [href]="reportsUrl" class="link-item">
					<div>
						<div class="header">
							<i class="reports"></i>
							Reports
						</div>
						<div class="description">View the results in generated reports</div>
					</div>
				</a>

				@if (isUnderDevelopment$ | async) {
					<div class="link-item" onclick="location.href='new_url1.html';">
						<div class="header">
							<i class="review"></i>
							Review
						</div>
						<div class="description">Test Artifacts review</div>
					</div>
				}

				@if (environment !== 'hero') {
					<a [href]="vehicleBookingsUrl" class="link-item">
						<div>
							<div class="header">
								<i class="booking"></i>
								Vehicle Booking
							</div>
							<div class="description">Book a date interval for test suite connected to a vehicle rig or event</div>
						</div>
					</a>
				}

				@if (isUnderDevelopment$ | async) {
					<a [href]="ddbUrl" class="link-item">
						<div>
							<div class="header">
								<i class="deliverydatabase"></i>
								Delivery Database
							</div>
							<div class="description">Approve software based on test results</div>
						</div>
					</a>
				}

				@if (isUnderDevelopment$ | async) {
					<div class="link-item" onclick="location.href='new_url1.html';">
						<div class="header">
							<i class="ecucompare"></i>
							ECU Compare
						</div>
						<div class="description">Suggest correct SW for vehicle/test period</div>
					</div>
				}
			</div>
		}
	</div>
</div>
