import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject } from '@angular/core';
import * as i3 from 'rxjs';
import { Subject } from 'rxjs';
import { switchMap, debounceTime, filter } from 'rxjs/operators';
import * as i1 from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import * as i2 from '@angular/router';
var LogLevel;
(function (LogLevel) {
  LogLevel[LogLevel["Trace"] = 0] = "Trace";
  LogLevel[LogLevel["Debug"] = 1] = "Debug";
  LogLevel[LogLevel["Information"] = 2] = "Information";
  LogLevel[LogLevel["Warning"] = 3] = "Warning";
  LogLevel[LogLevel["Error"] = 4] = "Error";
  LogLevel[LogLevel["Critical"] = 5] = "Critical";
})(LogLevel || (LogLevel = {}));
const LOGGER_CONFIG = new InjectionToken('env.config');
var LoggerEvents;
(function (LoggerEvents) {
  LoggerEvents[LoggerEvents["Flush"] = 1] = "Flush";
})(LoggerEvents || (LoggerEvents = {}));
class Logger {
  constructor(http, logConfig$, router) {
    this.http = http;
    this.logConfig$ = logConfig$;
    this.router = router;
    this.buffer = [];
    this.flush = new Subject();
    this.logFieldNames = {
      sessionId: "sessionId",
      level: "level",
      message: "message",
      time: "time",
      application: "application",
      applicationEnvironment: "applicationEnvironment",
      url: "url",
      service: "service",
      corellationId: "correlation-id",
      requestId: "request-id"
    };
    this.logConfig$.pipe(switchMap(logConfig => {
      this.logConfig = logConfig;
      return this.flush.pipe(debounceTime(logConfig.debounceTime ?? 0), filter(event => event === LoggerEvents.Flush));
    })).subscribe(() => this.flushBuffer());
  }
  log(data) {
    if (this.logConfig === undefined) return;
    if (LogLevel[data.level] < this.logConfig.logLevel) return;
    data.sessionId = localStorage.getItem('sessionId') || "";
    data.time = new Date().toISOString();
    data.application = this.logConfig.application;
    data.applicationEnvironment = this.logConfig.env;
    data.url = this.router.url;
    if (this.logConfig.console) {
      let logMessage = `[${data.time}][${data.level}] ${data.message}`;
      switch (LogLevel[data.level]) {
        case LogLevel.Trace:
          console.trace(logMessage);
          break;
        case LogLevel.Debug:
          console.log(logMessage);
          break;
        case LogLevel.Information:
          console.info(logMessage);
          break;
        case LogLevel.Warning:
          console.warn(logMessage);
          break;
        case LogLevel.Error:
          console.error(logMessage);
          break;
        case LogLevel.Critical:
          console.error(logMessage);
          break;
      }
    }
    this.buffer.push({
      data
    });
    this.flush.next(LoggerEvents.Flush);
  }
  flushBuffer() {
    const data = this.buffer.splice(0);
    if (data.length === 0) {
      return;
    }
    let requestBody = [];
    data.forEach(entry => requestBody.push(this.buildLogString(entry)));
    this.pushLog(requestBody);
  }
  buildLogString(entry) {
    const logString = {};
    Object.keys(this.logFieldNames).forEach(key => {
      logString[this.logFieldNames[key]] = entry.data[key];
    });
    return JSON.stringify(logString);
  }
  pushLog(logMessage) {
    let auth = localStorage.getItem('sessionId') || "anonymous";
    const headers = {
      "headers": new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': auth
      })
    };
    const data = {
      "query": "mutation ($log: [String!]!){createLog(log: $log)}",
      "variables": {
        "log": logMessage
      }
    };
    this.http.post(this.logConfig.graphqlUrl, data, headers).subscribe({
      next: () => {},
      error: error => {
        let time = new Date().toISOString();
        console.log(`[${time}][Error] Error sending logs ${error}`);
      }
    });
  }
  static {
    this.ɵfac = function Logger_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || Logger)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(LOGGER_CONFIG), i0.ɵɵinject(i2.Router));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: Logger,
      factory: Logger.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Logger, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: i3.Observable,
    decorators: [{
      type: Inject,
      args: [LOGGER_CONFIG]
    }]
  }, {
    type: i2.Router
  }], null);
})();
class TmtLoggerService {
  constructor(logger) {
    this.logger = logger;
    this.logTrace = (message, correlationId = "", requestId = "") => {
      this.log(LogLevel.Trace, message, correlationId, requestId);
      return this;
    };
    this.logDebug = (message, correlationId = "", requestId = "") => {
      this.log(LogLevel.Debug, message, correlationId, requestId);
      return this;
    };
    this.logInformation = (message, correlationId = "", requestId = "") => {
      this.log(LogLevel.Information, message, correlationId, requestId);
      return this;
    };
    this.logWarning = (message, correlationId = "", requestId = "") => {
      this.log(LogLevel.Warning, message, correlationId, requestId);
      return this;
    };
    this.logError = (message, correlationId = "", requestId = "") => {
      this.log(LogLevel.Error, message, correlationId, requestId);
      return this;
    };
    this.logCritical = (message, correlationId = "", requestId = "") => {
      this.log(LogLevel.Critical, message, correlationId, requestId);
      return this;
    };
  }
  flush() {
    this.logger.flushBuffer();
  }
  log(level, message, corellationId, requestId) {
    let data = {
      corellationId: corellationId,
      requestId: requestId,
      level: LogLevel[level],
      message: message
    };
    this.logger.log(data);
  }
  static {
    this.ɵfac = function TmtLoggerService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TmtLoggerService)(i0.ɵɵinject(Logger));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TmtLoggerService,
      factory: TmtLoggerService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TmtLoggerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: Logger
  }], null);
})();
class ErrorHandlerService {
  constructor(logger) {
    this.logger = logger;
  }
  handleError(error) {
    this.logger.logError(JSON.stringify(error, Object.getOwnPropertyNames(error)));
    throw error;
  }
  static {
    this.ɵfac = function ErrorHandlerService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ErrorHandlerService)(i0.ɵɵinject(TmtLoggerService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ErrorHandlerService,
      factory: ErrorHandlerService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ErrorHandlerService, [{
    type: Injectable
  }], () => [{
    type: TmtLoggerService
  }], null);
})();

/*
 * Public API Surface of tmt-logger
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ErrorHandlerService, LOGGER_CONFIG, LogLevel, TmtLoggerService };
