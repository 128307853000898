import { Injectable } from '@angular/core';

import { catchError, combineLatest, map, Observable, of, switchMap, take } from 'rxjs';

import { TmtLoggerService } from 'tmt-logger';

import { loginMutations } from 'src/app/graphql/mutations/login.mutations';
import { loginQueries } from 'src/app/graphql/queries/login.queries';
import { UserData } from 'src/app/models/user-data';
import { ApolloClientService } from 'src/app/services/apollo-client.service';
import { AuthService } from 'src/app/services/auth.service';

/**
 * Handles user session.
 */
@Injectable()
export class SessionService {
	/**
	 * Constructor
	 * @param apolloClientService To query auth layer graphql serverl
	 * @param loggerService To log events.
	 * @param authService To handle user data.
	 */
	constructor(
		private apolloClientService: ApolloClientService,
		private loggerService: TmtLoggerService,
		private authService: AuthService,
	) {}

	/**
	 * Validates a user session. Uses sessionID from localstorage.
	 * @returns True if session is valid, false otherwise.
	 */
	public validateSession$() {
		this.loggerService.logDebug('Before validating');
		return this.apolloClientService.authLayerClient.pipe(
			take(1),
			switchMap(client =>
				client.query({
					query: loginQueries.ping,
					errorPolicy: 'all',
					fetchPolicy: 'no-cache',
				}),
			),
			map(() => true),
			// TODO: Should we check for error type 401?
			catchError(err => {
				this.loggerService.logError('Validate session error: ' + err);
				this.authService.clearUserData();
				return of(false);
			}),
		);
	}

	/**
	 * Opens a session using a cola code.
	 * @param colaCode Code provided by cola.
	 * @param authProvider
	 * @returns Observable of user data representing the user session.
	 */
	public openSession$(colaCode, authProvider): Observable<UserData | undefined> {
		return this.apolloClientService.authLayerClient.pipe(
			take(1),
			switchMap(client =>
				client.mutate({
					mutation: loginMutations.openSession,
					variables: { code: colaCode, authProvider: authProvider },
					fetchPolicy: 'no-cache',
				}),
			),
			switchMap((response: any) => {
				this.loggerService.logDebug('openSession response: ' + JSON.stringify(response));
				const sessionId = response.data.openSession.SessionId;
				localStorage.setItem('sessionId', sessionId);
				return combineLatest({
					sessionId: of(sessionId),
					userDetails: this.apolloClientService.authLayerClient.pipe(
						take(1),
						switchMap(client =>
							client.query({
								query: loginQueries.getUserDetails,
								variables: { sessionId: sessionId },
								errorPolicy: 'all',
								fetchPolicy: 'no-cache',
								context: {
									headers: {
										authorization: sessionId, // we cannot expect SessionID to be set in authService, here. So provide the header explicitly.
									},
								},
							}),
						),
						catchError(error => {
							localStorage.setItem('Error', '418: no result from getUserDetail' + error);
							return of(undefined);
						}),
					),
				});
			}),
			map(data => {
				if (data && data.userDetails) {
					return {
						userName: (data.userDetails as any).data.getUserDetails.Username,
						company: (data.userDetails as any).data.getUserDetails.Company,
						sessionId: data.sessionId,
					};
				}
				return undefined;
			}),
		);
	}
}
