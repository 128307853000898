import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TmtLoggerService } from 'tmt-logger';

@Injectable()
export class UtilsService {
	constructor(
		private datePipe: DatePipe,
		private loggerService: TmtLoggerService,
	) {}

	public formatDate(date: Date | undefined): string | null {
		const val = this.datePipe.transform(date, 'yyyy-MM-dd hh:mm:ss');
		return val !== null ? val : '';
	}

	public navigate(url: string) {
		this.loggerService.logDebug('Navigating to: ' + url).flush();
		window.location.href = url;
	}
}
