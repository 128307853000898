import { DatePipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { ErrorHandlerService, LOGGER_CONFIG } from 'tmt-logger';

import { routes } from 'src/app/routes/routes';
import { ApolloClientService } from 'src/app/services/apollo-client.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { LaunchDarklyLogger } from 'src/app/services/launchdarkly-logger.service';
import { SessionService } from 'src/app/services/session.service';
import { UtilsService } from 'src/app/services/utils.service';

import { AppComponent } from './app/app.component';
import { LoginService } from './app/components/login/login.service';
import { AuthService } from './app/services/auth.service';
import { LaunchDarklyService } from './app/services/launchdarkly.service';

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(BrowserModule, FormsModule),
		AppConfigService,
		{
			provide: APP_INITIALIZER,
			useFactory: (appConfigService: AppConfigService) => () => appConfigService.load(),
			deps: [AppConfigService],
			multi: true,
		},
		{
			provide: LOGGER_CONFIG,
			useFactory: (appConfigService: AppConfigService) => appConfigService.logConfig$,
			deps: [AppConfigService],
		},
		{
			provide: ErrorHandler,
			useClass: ErrorHandlerService,
		},
		LoginService,
		AuthService,
		SessionService,
		ApolloClientService,
		UtilsService,
		LaunchDarklyLogger,
		LaunchDarklyService,
		DatePipe,
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi()),
		provideRouter(routes),
	],
}).catch(err => console.error(err));
