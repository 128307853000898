import { Injectable } from '@angular/core';

import { TmtLoggerService } from 'tmt-logger';
import * as LDClient from 'launchdarkly-js-client-sdk';

@Injectable()
export class LaunchDarklyLogger implements LDClient.LDLogger {
	constructor(private loggerService: TmtLoggerService) {}

	debug = (message: string): void => {
		this.loggerService.logDebug(message);
	};

	error = (message: string): void => {
		this.loggerService.logError(message);
	};

	info = (message: string): void => {
		this.loggerService.logInformation(message);
	};

	warn = (message: string): void => {
		this.loggerService.logWarning(message);
	};
}
