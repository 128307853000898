import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { map } from 'rxjs';

import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { ReleaseNotesService } from 'src/app/services/release-notes.service';
import { GroupedReleaseNotesComponent } from 'src/app/components/release-notes/grouped-release-notes/grouped-release-notes.component';
import { AuthService } from 'src/app/services/auth.service';
import { LaunchDarklyService } from 'src/app/services/launchdarkly.service';
import { environment } from 'src/environments/environment';
import { ReleaseNotesComponent } from 'src/app/components/release-notes/release-notes/release-notes.component';

/**
 * Header contanining navigation and release notes.
 */
@Component({
	selector: 'app-header',
	standalone: true,
	imports: [MenubarModule, AvatarModule, RouterLink, MenuModule, ButtonModule, OverlayPanelModule, BadgeModule, AsyncPipe, ReleaseNotesComponent, GroupedReleaseNotesComponent],
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss',
	providers: [ReleaseNotesService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
	/**
	 * Title shown in header.
	 */
	public title = 'TEST TOOLS';

	/**
	 * Navigation items.
	 */
	public items = [];

	/**
	 * Flags used to determine what content to show in header.
	 */
	public isStaging$ = this.launchDarklyService.launchDarklyFlags$.pipe(map(flags => flags['staging']));

	public isAdmin$ = this.launchDarklyService.launchDarklyFlags$.pipe(map(flags => flags['admin']));

	/**
	 * String to append to title for certain environments.
	 */
	public fullEnvironmentString$ = this.launchDarklyService.launchDarklyFlags$.pipe(
		map(flags => {
			const logConfig = environment.logConfig.env;
			const stagingString = flags['staging'] ? ' staging ' : ' release';
			if (logConfig === 'dev' || flags['staging']) {
				return ' - ' + logConfig + stagingString;
			} else {
				return '';
			}
		}),
	);

	/**
	 * Amount of unreleased commits to display as release notes.
	 */
	public unreleasedCommits$ = this.releaseNotesService.releaseNotesStaging$;

	/**
	 * Number of items in the list of unrelease commits.
	 */
	public unreleasedCommitsCount$ = this.unreleasedCommits$.pipe(map(releaseNotes => releaseNotes.length));

	/**
	 * Production release notes.
	 */
	public releaseNotesRelease$ = this.releaseNotesService.releaseNotesRelease$;

	/**
	 * Maximum amount of release notes to display.
	 */
	public releaseNoteCountLimit = 50;

	/**
	 * Constructor.
	 * @param authService To get user session status.
	 * @param launchDarklyService To get user feature flags.
	 * @param releaseNotesService To get release notes.
	 */
	constructor(
		public authService: AuthService,
		private launchDarklyService: LaunchDarklyService,
		private releaseNotesService: ReleaseNotesService,
	) {}
}
