import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';

import { map, tap } from 'rxjs';

import { LaunchDarklyService } from 'src/app/services/launchdarkly.service';
import { UnderMaintenanceComponent } from 'src/app/components/under-maintenance/under-maintenance.component';

import { HeaderComponent } from './components/header/header.component';

/**
 * App component.
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [HeaderComponent, RouterOutlet, UnderMaintenanceComponent, AsyncPipe],
})
export class AppComponent {
	public underMaintenance$ = this.ldService.launchDarklyFlags$.pipe(map(flags => flags['under-maintenance'])).pipe(tap(value => {}));

	/**
	 * Constructor.
	 * @param ldService To get maintenance status.
	 */
	constructor(private ldService: LaunchDarklyService) {}
}
