import { Routes } from '@angular/router';

import { LoginComponent } from 'src/app/components/login/login.component';
import { NavigationComponent } from 'src/app/components/navigation/navigation.component';

export const routes: Routes = [
	{
		path: '',
		component: NavigationComponent,
	},
	{
		path: 'login',
		component: LoginComponent,
	},
];
