import { Injectable } from '@angular/core';

import { Subject, shareReplay } from 'rxjs';

import { EnvironmentConfig } from 'tmt-logger';

import { environment } from 'src/environments/environment';

@Injectable()
export class AppConfigService {
	private logConfigSubject = new Subject<EnvironmentConfig>();

	public logConfig$ = this.logConfigSubject.asObservable().pipe(shareReplay(1));

	private configuration: any;

	constructor() {}

	public load() {
		this.configuration = environment;
		this.logConfigSubject.next(this.configuration.logConfig);
	}

	public getConfig(): any {
		return this.configuration;
	}

	public getLoginUrl(): string {
		if (this.getConfig().auth_provider === 'azure')
			return `${this.getConfig().auth_url}?response_type=code&client_id=${this.getConfig().auth_client_id}&redirect_uri=${this.getConfig().redirect_url}&response_mode=${this.getConfig().auth_response_mode}&scope=${this.getConfig().auth_scope}`;
		else
			return `${this.getConfig().auth_url}?response_type=code&client_id=${this.getConfig().auth_client_id}&nonce=${`${window.location.protocol}//${window.location.host}`}&redirect_uri=${this.getConfig().redirect_url}`;
	}

	public getDdbUrl(): string {
		return `${this.getConfig().deliveryDatabase_url}/?sessionId=${localStorage.getItem('sessionId')}&username=${localStorage.getItem('username')}&company=${localStorage.getItem('company')}`;
	}

	public getConfigUrl(): string {
		return `${this.getConfig().configurator_url}/#/?sessionId=${localStorage.getItem('sessionId')}&username=${localStorage.getItem('username')}&company=${localStorage.getItem('company')}`;
	}

	public getExecutorUrl(): string {
		return `${this.getConfig().executor_url}/?sessionId=${localStorage.getItem('sessionId')}&username=${localStorage.getItem('username')}&company=${localStorage.getItem('company')}`;
	}

	public getResultsUrl(): string {
		return `${this.getConfig().results_url}/?sessionId=${localStorage.getItem('sessionId')}&username=${localStorage.getItem('username')}&company=${localStorage.getItem('company')}`;
	}

	public getReportsUrl(): string {
		return `${this.getConfig().reports_url}/?sessionId=${localStorage.getItem('sessionId')}&username=${localStorage.getItem('username')}&company=${localStorage.getItem('company')}`;
	}
}
