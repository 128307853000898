@if (authService.isLoggedIn$ | async) {
	@for (group of groupedReleaseNotes$ | async; track group.releaseTime) {
		<div class="release-note-group">
			<div class="release-date">
				<b>{{ group.releaseTime | date: 'yyyy-MM-dd' }}</b>
			</div>
			@for (note of group.notes; track note.IssueTitle) {
				<app-release-note [releaseNote]="note" [editable]="editable"></app-release-note>
			}
		</div>
	}
}
