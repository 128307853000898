@if (authService.isLoggedIn$ | async) {
	@for (releaseNote of releaseNotesFiltered$ | async; track releaseNote.CommitTimestamp) {
		<div>
			<div class="release-note-container">
				<div>
					<b>{{ releaseNote.ProjectName }}</b>
					: {{ releaseNote.CommitTimestamp | date: 'yyyy-MM-dd HH:mm' }}
				</div>
				<div>{{ releaseNote.CommitTitle }}</div>
				<div [hidden]="!(releaseNote.IssueLabels !== '--')">{{ releaseNote.IssueLabels }}</div>
			</div>
		</div>
	}
}
