import { Injectable } from '@angular/core';

import { filter, map, mergeMap, shareReplay, switchMap, take, tap, toArray } from 'rxjs/operators';
import { BehaviorSubject, Observable, combineLatest, from } from 'rxjs';

import { TmtLoggerService } from 'tmt-logger';

import { ApolloClientService } from 'src/app/services/apollo-client.service';
import { ReleaseNoteGroup } from 'src/app/models/release-note-group';
import { releaseNotesMutations } from 'src/app/graphql/mutations/release-notes.mutations';
import { releaseNotesQueries } from 'src/app/graphql/queries/release-notes.queries';
import { ReleaseNote } from 'src/app/models/release-note';

import { LaunchDarklyService } from './launchdarkly.service';

@Injectable()
export class ReleaseNotesService {
	public refreshSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);

	constructor(
		private apolloClientService: ApolloClientService,
		private loggerService: TmtLoggerService,
		private launchDarklyService: LaunchDarklyService,
	) {}

	public releaseNotesStaging$: Observable<ReleaseNote[]> = this.apolloClientService.abstractionLayerClient$.pipe(
		switchMap(client =>
			this.apolloClientService.observableWatchQuery$(client, {
				query: releaseNotesQueries.releaseNotesGetAllStaging,
				errorPolicy: 'all',
			}),
		),
		map(response => {
			const notes = response.data?.releaseNotesGetAllStaging || [];
			return notes.map((note: any) => {
				const { __typename, ...releaseNote } = note;
				return releaseNote as ReleaseNote;
			});
		}),
		map(result => {
			return result
				.filter(releaseNote => releaseNote.CommitMessage.indexOf("Merge branch 'main'") === -1)
				.sort((a, b) => new Date(b.CommitTimestamp).getTime() - new Date(a.CommitTimestamp).getTime());
		}),
		shareReplay(1),
	);

	public releaseNotesRelease$: Observable<ReleaseNote[]> = combineLatest({
		client: this.apolloClientService.abstractionLayerClient$,
		refreshSubject: this.refreshSubject,
	}).pipe(
		map(source => source.client),
		switchMap(client =>
			this.apolloClientService.observableWatchQuery$(client, {
				query: releaseNotesQueries.releaseNotesGetAllRelease,
				errorPolicy: 'all',
				fetchPolicy: 'no-cache',
			}),
		),
		map(response => response.data.releaseNotesGetAllRelease || []),
		mergeMap(data =>
			from(data).pipe(
				switchMap(item => {
					const labels = (item as ReleaseNote).IssueLabels?.split(',').map(label => label.trim()) || [];
					return this.launchDarklyService.areAllFlagsTrue$(labels).pipe(
						map(result => ({ item, result })),
						take(1),
					);
				}),
				filter(data => !!data.result),
				map(data => data.item),
				toArray(),
			),
		),
		map(items =>
			items.map(note => {
				const { __typename, ...releaseNote } = note as { __typename: string } & ReleaseNote;
				return releaseNote;
			}),
		),
		map(releaseNotes => releaseNotes.sort((a, b) => new Date(b.ReleaseTime).getTime() - new Date(a.ReleaseTime).getTime())),
		shareReplay(1),
	);

	public updateReleaseNote$(oldTitle: string, newTitle: string, labels: string) {
		this.loggerService.logDebug('Updating release notes');
		return this.apolloClientService.abstractionLayerClient$.pipe(
			switchMap(client =>
				client.mutate({
					mutation: releaseNotesMutations.updateReleaseNote,
					variables: {
						oldTitle: oldTitle,
						newTitle: newTitle,
						labels: labels,
					},
				}),
			),
			map((response: any) => {
				const notes = response.data.releaseNotesMutations || [];
				return notes.map((note: any) => {
					const { __typename, ...releaseNote } = note;
					return releaseNote as ReleaseNote;
				});
			}),
			tap(() => this.refreshSubject.next(true)),
			take(1),
		);
	}

	public groupReleaseNotes(input: ReleaseNote[]): ReleaseNoteGroup[] {
		const groupedNotes: { [key: string]: ReleaseNote[] } = {};

		input.forEach(note => {
			const date = new Date(note.ReleaseTime).toISOString().split('T')[0]; // Get YYYY-MM-DD
			if (!groupedNotes[date]) {
				groupedNotes[date] = [];
			}
			groupedNotes[date].push(note);
		});

		// Filter unique issue titles.
		for (const group of Object.keys(groupedNotes)) {
			groupedNotes[group] = groupedNotes[group].filter((item, index, arr) => arr.findIndex(i => i.IssueTitle === item.IssueTitle) === index);
		}

		return Object.keys(groupedNotes)
			.map(date => ({
				releaseTime: date,
				notes: groupedNotes[date],
			}))
			.sort((a, b) => new Date(b.releaseTime).getTime() - new Date(a.releaseTime).getTime());
	}
}
