import { Injectable } from '@angular/core';

import { Observable, distinctUntilChanged, map, of, shareReplay, switchMap } from 'rxjs';

import * as LDClient from 'launchdarkly-js-client-sdk';

import { LaunchDarklyLogger } from 'src/app/services/launchdarkly-logger.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

/**
 * Provides observable launchdarkly clients.
 */
@Injectable()
export class LaunchDarklyService {
	/**
	 * Observable of the LaunchDarkly client, which emits a new client when user data changes.
	 */
	private launchDarklyClient$ = this.authService.userData$.pipe(
		map(userData => {
			const context = {
				kind: 'user',
				key: userData?.userName ? userData.userName : 'anon',
				company: userData?.company ? userData.company : 'N-A',
				anonymous: !userData?.sessionId,
			};
			const options: LDClient.LDOptions = {
				logger: this.ldLogger,
			};
			return LDClient.initialize(environment.launchDarklyClientId, context, options);
		}),
		shareReplay(1),
	);

	/**
	 * Observable of the LaunchDarkly flags, using the client observable above.
	 * Creates an observable of flag changes in the current client which are then shared with subscribers.
	 */
	public launchDarklyFlags$ = this.launchDarklyClient$.pipe(
		switchMap(
			client =>
				new Observable<{ [key: string]: any }>(subscriber => {
					const onReady = () => {
						try {
							console.log('LaunchDarkly client is ready');
							subscriber.next(client.allFlags());
						} catch (error) {
							console.error('Error in ready handler:', error);
							subscriber.error(error);
						}
					};

					const onChange = () => {
						try {
							console.log('LaunchDarkly client flags changed');
							subscriber.next(client.allFlags());
						} catch (error) {
							console.error('Error in change handler:', error);
							subscriber.error(error);
						}
					};

					client.on('ready', onReady);
					client.on('change', onChange);

					// Cleanup function
					return () => {
						client.off('ready', onReady);
						client.off('change', onChange);
					};
				}),
		),
		distinctUntilChanged(),
		shareReplay(1),
	);

	constructor(
		private authService: AuthService,
		private ldLogger: LaunchDarklyLogger,
	) {}

	public areAllFlagsTrue$(inputFlags: string[]): Observable<boolean> {
		if (!inputFlags.length) {
			return of(true);
		}

		return this.launchDarklyFlags$.pipe(
			map(launchDarklyFlags => {
				return inputFlags.every(flag => (flag.length ? launchDarklyFlags[flag] : true));
			}),
		);
	}
}
