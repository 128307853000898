import { Injectable } from '@angular/core';

import { BehaviorSubject, map, shareReplay, distinctUntilChanged } from 'rxjs';

import { UserData } from 'src/app/models/user-data';

@Injectable()
export class AuthService {
	constructor() {}

	private sessionId: string | null = null;

	/**
	 * Observable of user data. Gets initial values from local storage.
	 */
	private userDataSubject: BehaviorSubject<UserData> = new BehaviorSubject({
		userName: localStorage.getItem('username') ?? '',
		sessionId: localStorage.getItem('sessionId') ?? '',
		company: localStorage.getItem('company') ?? '',
	});

	public userData$ = this.userDataSubject.asObservable().pipe(
		shareReplay(1),
		// Ensure we only emit when actual values have changed.
		distinctUntilChanged((prev, curr) => prev.company === curr.company && prev.sessionId === curr.sessionId && prev.userName === curr.userName),
	);

	/**
	 * Observable of logged in status, based on user data observable.
	 */
	public isLoggedIn$ = this.userData$.pipe(
		map(data => {
			return !!data.sessionId;
		}),
		distinctUntilChanged(),
	);

	/**
	 * Set user data, in local storage for future use, and also to subject for immediate access.
	 * @param userData User data to set.
	 */
	public setUserData(userData: UserData) {
		if (!userData) {
			return;
		}
		localStorage.setItem('username', userData.userName);
		localStorage.setItem('company', userData.company);
		localStorage.setItem('sessionId', userData.sessionId);
		this.userDataSubject.next(userData);
	}

	/**
	 * Clears all user data to remove current session.
	 */
	public clearUserData() {
		localStorage.removeItem('username');
		localStorage.removeItem('sessionId');
		localStorage.removeItem('company');
		this.userDataSubject.next({ userName: '', sessionId: '', company: '' }); //Ensure user data is cleared
	}
}
