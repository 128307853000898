import { AsyncPipe, DatePipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { catchError, map, Observable, of } from 'rxjs';

import { ReleaseNoteComponent } from 'src/app/components/release-notes/release-note/release-note.component';
import { ReleaseNote } from 'src/app/models/release-note';
import { AuthService } from 'src/app/services/auth.service';

/**
 * Displays release notes in a simple list.
 * For now, assumes release note is a "git commit" note with name in CommitTitle property.
 */
@Component({
	selector: 'app-release-notes',
	standalone: true,
	imports: [AsyncPipe, DatePipe, JsonPipe, ReleaseNoteComponent],
	templateUrl: './release-notes.component.html',
	styleUrl: './release-notes.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseNotesComponent implements OnInit {
	/**
	 * Input release notes to display.
	 */
	@Input({ required: true }) releaseNotes$!: Observable<ReleaseNote[]>;

	/**
	 * Maximum amount of release notes to display.
	 */
	@Input() notesLimit = 0;

	/**
	 * Filtered release notes to display in template.
	 */
	public releaseNotesFiltered$!: Observable<ReleaseNote[]>;

	/**
	 * Constructor.
	 * @param authService To get user signed in status.
	 */
	constructor(public authService: AuthService) {}

	// On init, sort and filter input release notes.
	ngOnInit(): void {
		this.releaseNotesFiltered$ = this.releaseNotes$.pipe(
			map(result => {
				let filteredResults = result
					.filter(releaseNote => releaseNote.CommitMessage.indexOf("Merge branch 'main'") === -1)
					.sort((a, b) => new Date(b.CommitTimestamp).getTime() - new Date(a.CommitTimestamp).getTime());

				if (this.notesLimit > 0) {
					filteredResults = filteredResults.slice(0, this.notesLimit);
				}

				return filteredResults;
			}),
			catchError(error => {
				console.error('Error getReleaseNotes:', error);
				return of([]);
			}),
		);
	}
}
