import { AsyncPipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { map, take } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';

import { ReleaseNote } from 'src/app/models/release-note';
import { LaunchDarklyService } from 'src/app/services/launchdarkly.service';
import { ReleaseNotesService } from 'src/app/services/release-notes.service';

/**
 * Displays a single release note with editing functionality.
 * Expects release note title to be in the IssueTitle property,
 * so will only work with production release notes, for now.
 */
@Component({
	selector: 'app-release-note',
	standalone: true,
	imports: [ButtonModule, ReactiveFormsModule, InputSwitchModule, AsyncPipe, JsonPipe],
	templateUrl: './release-note.component.html',
	styleUrl: './release-note.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseNoteComponent implements OnInit {
	/**
	 * Release note to display/edit.
	 */
	@Input({ required: true }) releaseNote!: ReleaseNote;

	/**
	 * If editing functionality should be enabled. Still also requires user to be admin.
	 */
	@Input() editable = false;

	/**
	 * Tracks editing status of item.
	 */
	public isEditing = false;

	/**
	 * Editing is only available for admin users.
	 */
	public isAdmin$ = this.launchDarklyService.launchDarklyFlags$.pipe(map(flags => flags['admin']));

	/**
	 * Form group of current release note.
	 */
	public releaseNotesFormGroup = new FormGroup({
		releaseNoteTitle: new FormControl<string>(''),
		releaseNoteHidden: new FormControl<boolean>(false),
	});

	constructor(
		private releaseNotesService: ReleaseNotesService,
		private launchDarklyService: LaunchDarklyService,
	) {}

	ngOnInit(): void {
		this.releaseNotesFormGroup.setValue({
			releaseNoteTitle: this.releaseNote.IssueTitle,
			releaseNoteHidden: this.releaseNote.IssueLabels.split(',').findIndex(note => note.trim() === 'admin') !== -1,
		});
		this.releaseNotesFormGroup.updateValueAndValidity();
	}

	public saveReleaseNote(): void {
		const value = this.releaseNotesFormGroup.value;

		let labels = this.releaseNote.IssueLabels;

		if (value.releaseNoteHidden) {
			const alreadyHidden = labels.split(',').findIndex(value => value.trim() === 'admin') !== -1;

			if (!alreadyHidden) {
				if (!labels.length) {
					labels = 'admin';
				} else {
					labels = labels.concat(',admin');
				}
			}
		} else {
			labels = labels
				.split(',')
				.filter(item => item !== 'admin')
				.map(item => item.trim())
				.join(',');
		}

		if (value.releaseNoteTitle) {
			this.releaseNotesService.updateReleaseNote$(this.releaseNote.IssueTitle, value.releaseNoteTitle, labels).pipe(take(1)).subscribe();
		}

		this.isEditing = false;
	}
}
