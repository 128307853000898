import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { TmtLoggerService } from 'tmt-logger';

import { loginEndForWindowsApps } from 'src/app/components/login/loginJSFunctions.js';
import { UserData } from 'src/app/models/user-data';
import { AppConfigService } from 'src/app/services/app-config.service';
import { AuthService } from 'src/app/services/auth.service';
import { SessionService } from 'src/app/services/session.service';
import { UtilsService } from 'src/app/services/utils.service';

/**
 * Handles login logic, validating and updating user session data.
 */
@Injectable()
export class LoginService {
	/**
	 * Constructor.
	 * @param appConfigService To get configuration settings.
	 * @param loggerService To log status.
	 * @param authService To access and edit configuration.
	 * @param sessionService To handle user session.
	 * @param router To handle navigation.
	 * @param utilityService To navigate to external site.
	 */
	constructor(
		private appConfigService: AppConfigService,
		private loggerService: TmtLoggerService,
		private authService: AuthService,
		private sessionService: SessionService,
		private router: Router,
		private utilityService: UtilsService,
	) {}

	/**
	 * Returns an observable which performs necessary operations to validate and/or create user session.
	 * @param origin$ Used to redirect back to origin when third party is using sign-in.
	 * @param colaCode$ Used to create a session when code is provided by cola.
	 * @returns Observable representing the user session handling.
	 */
	public handleUserSession$(origin$: Observable<string>, colaCode$: Observable<string>) {
		return colaCode$.pipe(
			switchMap(colaCode => {
				// First, if we have a cola code, we should use it to open a session.
				// Otherwise, check current state to validate existing session.
				if (colaCode) {
					// If we have a cola code.
					return combineLatest({
						origin: origin$,
						userData: this.sessionService.openSession$(colaCode, this.appConfigService.getConfig().auth_provider).pipe(
							take(1),
							tap(data => {
								// If data from cola does not contain a session ID, redirect to landing page.
								// Do not retry autentication, as that could cause a loop if errors persist.
								if (!data?.sessionId) {
									this.router.navigateByUrl('');
								}
								this.authService.setUserData(data!);
							}),
						),
					}).pipe(
						filter(data => !!data.userData),
						tap(data => {
							if (data.origin) {
								this.returnToOrigin(data.origin, data.userData!);
							} else {
								this.router.navigateByUrl('');
							}
						}),
					);
				} else {
					// If no cola code, validate session if exists.
					return combineLatest({
						origin: origin$,
						userData: this.authService.userData$.pipe(take(1)),
						isValidSession: this.authService.isLoggedIn$.pipe(
							take(1),
							switchMap(isLoggedIn => {
								if (isLoggedIn) {
									return this.sessionService.validateSession$().pipe(take(1));
								} else {
									return of(false);
								}
							}),
						),
					}).pipe(
						map(data => {
							if (data.isValidSession && data.origin) {
								this.returnToOrigin(data.origin, data.userData);
							} else if (data.isValidSession) {
								this.router.navigateByUrl('');
							} else {
								this.authenticateOnCola();
							}
						}),
					);
				}
			}),
		);
	}

	/**
	 * Navigates to a provided origin url.
	 * @param originUrl Url to navigate to.
	 * @param userData User data which should be provided as query params to origin.
	 */
	private returnToOrigin(originUrl: string, userData: UserData) {
		localStorage.removeItem('origin'); // Ensure that origin is cleared for future sign-ins.

		this.loggerService.logDebug('Return to origin: ' + originUrl + ' ' + userData.sessionId);
		if (originUrl !== null) {
			//If the redirect url is "Windows", we call the function which calls function in windows app
			if (originUrl.toLowerCase() === 'windows') {
				loginEndForWindowsApps(userData.sessionId, userData.userName, userData.company);
			}
			this.loggerService.logDebug('redirect');
			if (originUrl.includes('?')) {
				originUrl += `&sessionId=${userData.sessionId}&username=${userData.userName}&company=${userData.company}`;
			} else {
				originUrl += `?sessionId=${userData.sessionId}&username=${userData.userName}&company=${userData.company}`;
			}
			this.utilityService.navigate(originUrl);
		}
	}

	/**
	 * Redirects to cola for authentication.
	 */
	private authenticateOnCola() {
		this.utilityService.navigate(this.appConfigService.getLoginUrl());
	}
}
