import { gql } from 'apollo-angular';

export class releaseNotesMutations {
	public static updateReleaseNote = gql`
		mutation releaseNotesUpdateNote($oldTitle: String!, $newTitle: String!, $labels: String!) {
			releaseNotesUpdateNote(oldTitle: $oldTitle, newTitle: $newTitle, Label: $labels) {
				CommitHash
				CommitMessage
				CommitDescription
				CommitTitle
				CommitTimestamp
				ProjectTitle
				ProjectName
				IssueKey
				IssueTitle
				IssueLabels
				ReleaseTime
			}
		}
	`;
}
