<p-menubar class="menu p-header" [model]="items">
	<ng-template pTemplate="start">
		<a routerLink="/">
			<div class="title desktop-only">{{ title + (fullEnvironmentString$ | async) }}</div>
			<i class="pi pi-home mobile-only title-icon"></i>
		</a>
	</ng-template>
	<ng-template pTemplate="item" let-item>
		@if (item.route) {
			<a [routerLink]="item.route" class="p-menuitem-link">
				<span [class]="item.icon"></span>
				<span class="ml-2">{{ item.label }}</span>
			</a>
		} @else if (item.url) {
			<a [href]="item.url" class="p-menuitem-link">
				<span [class]="item.icon"></span>
				<span class="ml-2">{{ item.label }}</span>
			</a>
		} @else {
			<div class="p-menuitem-link">
				<span [class]="item.icon"></span>
				<span class="ml-2">{{ item.label }}</span>
				<span class="pi pi-fw pi-angle-down ml-2"></span>
			</div>
		}
	</ng-template>
	<ng-template pTemplate="end">
		<div class="end-items">
			@if (authService.isLoggedIn$ | async) {
				@if (isStaging$ | async) {
					<div style="cursor: pointer" class="release-notes-menu" (click)="unreleasedCommits.toggle($event)" (keydown)="unreleasedCommits.toggle($event)" tabindex="0">
						@if (unreleasedCommitsCount$ | async; as releaseNoteCount) {
							@if (releaseNoteCount > 0) {
								<div class="release-note-badge">
									<p-badge [value]="releaseNoteCountLimit === 0 || releaseNoteCount < releaseNoteCountLimit ? releaseNoteCount : releaseNoteCountLimit + '+'"></p-badge>
								</div>
							}
						}
						Unreleased commits
					</div>
				}
				@if (!(isStaging$ | async) || (isAdmin$ | async)) {
					<div style="cursor: pointer" class="release-notes-menu" (click)="productionReleaseNotes.toggle($event)" (keydown)="productionReleaseNotes.toggle($event)" tabindex="0">
						Release notes
					</div>
				}
			}

			<div class="user-icon-container" style="cursor: pointer" (click)="menu.toggle($event)" (keydown)="menu.toggle($event)" tabindex="0">
				<img class="user-icon" alt="User icon" src="https://www.svgrepo.com/show/384671/account-avatar-profile-user-14.svg" />
			</div>
			<img class="traton-logo" alt="Traton logo" src="theme/assets/logos/TRATON_Logo_RGB_white.png" />
		</div>
	</ng-template>
</p-menubar>

<p-overlayPanel #unreleasedCommits>
	<div class="release-notes-list">
		<app-release-notes [releaseNotes$]="unreleasedCommits$" [notesLimit]="releaseNoteCountLimit"></app-release-notes>
	</div>
</p-overlayPanel>

<p-overlayPanel #productionReleaseNotes>
	<div class="release-notes-list">
		<app-grouped-release-notes [releaseNotes$]="releaseNotesRelease$" [editable]="true"></app-grouped-release-notes>
	</div>
</p-overlayPanel>

<p-overlayPanel #menu>
	@if (authService.userData$ | async; as userData) {
		<div class="session-content">
			@if (!!userData.sessionId) {
				<div>
					<b>{{ userData.userName }}</b>
				</div>
				<div>{{ userData.company }}</div>
			} @else {
				<a style="cursor: pointer" href="login">Login</a>
			}
		</div>
	}
</p-overlayPanel>
