export const environment = {
	production: false,
	authLayer_staging: 'https://ehlfaue7jfexfalnchzbb672sq.appsync-api.eu-north-1.amazonaws.com/graphql',
	authLayer_release: 'https://tfsusyfgjnaitctqbnqad3v2bq.appsync-api.eu-north-1.amazonaws.com/graphql',
	abstractionLayerStagingUrl: 'https://quobkvexmre7blsb7ltpdz2xxi.appsync-api.eu-north-1.amazonaws.com/graphql',
	abstractionLayerReleaseUrl: 'https://5oetsllb2jfelbghdyob2uy2r4.appsync-api.eu-north-1.amazonaws.com/graphql',
	requirementsurl: 'https://polarion-dev.scania.com/polarion/#/',
	redirect_url: 'https://testtools.testtools.devtest.gf.aws.scania.com/login',
	auth_provider: 'cola',
	auth_client_id: '2k1a828hcuccld58ve113mjfok',
	auth_url: 'https://auth.collaboration-layer.com/oauth2/authorize',
	logConfig: {
		application: 'testtools',
		env: 'dev',
		graphqlUrl: 'https://kftht5tfp5b6tfwm6kzqa7ijzu.appsync-api.eu-north-1.amazonaws.com/graphql',
		logLevel: 1,
		console: true,
		debounceTime: 5000,
	},
	launchDarklyClientId: '664590c02c638010dc55095f',
	deliveryDatabase_url: 'https://deliveries.testtools.devtest.gf.aws.scania.com/',
	configurator_url: 'https://configurator.testtools.devtest.gf.aws.scania.com',
	executor_url: 'https://executor.testtools.devtest.gf.aws.scania.com',
	results_url: 'https://results.testtools.devtest.gf.aws.scania.com',
	reports_url: 'https://reports.testtools.devtest.gf.aws.scania.com',
	environment: 'dev',
};
