import { Injectable } from '@angular/core';

import { Observable, combineLatest, filter, map, shareReplay } from 'rxjs';

import { v4 as uuid } from 'uuid';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject, ObservableQuery, from } from '@apollo/client/core';
import { WatchQueryOptions } from 'apollo-angular';
import { TmtLoggerService } from 'tmt-logger';

import { environment } from 'src/environments/environment';
import { LaunchDarklyService } from 'src/app/services/launchdarkly.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppConfigService } from 'src/app/services/app-config.service';

/**
 * Service providing apollo clients.
 */
@Injectable()
export class ApolloClientService {
	/**
	 * Observable of data which is common between all available clients.
	 */
	private commonClientConfiguration$ = this.authService.userData$.pipe(
		map(userData => {
			// Set headers for authentication and correlation.
			const headerLink = setContext((_, { headers }) => {
				this.loggerService.logDebug('setContext');
				const correlationId = uuid();
				const requestId = uuid();
				const sessionId = userData.sessionId || 'anonymous';
				this.loggerService.logDebug('graphql sessionId: ' + sessionId);

				// Set headers. Uses special logic to preserve sessionID in case it has been explicitly set in request. Only if no other sessionID is provided.
				return {
					headers: {
						...headers,
						'x-correlation-id': correlationId,
						'x-request-id': requestId,
						authorization: sessionId === 'anonymous' && headers?.authorization ? headers.authorization : sessionId,
					},
				};
			});

			// Configure error handling.
			const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
				this.loggerService.logDebug('onError');
				const correlationId = operation.getContext()['headers']['x-correlation-id'];
				if (graphQLErrors) {
					graphQLErrors.forEach(({ message, locations, path }) => {
						this.loggerService.logError(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, correlationId);
					});
				}

				if (networkError) {
					if (networkError.message.includes('401') || networkError.message.includes('403')) {
						this.authService.clearUserData();
					}
					this.loggerService.logError(`[Network error]: ${JSON.stringify(networkError)}`, correlationId);
				}
			});

			const timeStartLink = new ApolloLink((operation, forward) => {
				this.loggerService.logDebug('forward start');
				const correlationId = operation.getContext()['headers']['x-correlation-id'];
				const startTime = new Date();
				this.loggerService.logInformation(`[${operation.operationName}] Sending request`, correlationId);
				operation.setContext({ start: startTime });
				this.loggerService.logDebug('forward end');
				return forward(operation);
			});

			const timeEndLink = new ApolloLink((operation, forward) => {
				this.loggerService.logDebug('ApolloLink');
				return forward(operation).map(data => {
					const correlationId = operation.getContext()['headers']['x-correlation-id'];
					const duration = new Date().getTime() - operation.getContext()['start'].getTime();
					this.loggerService.logInformation(`[${operation.operationName}] took ${duration} to complete`, correlationId);
					return data;
				});
			});

			return {
				headerLink: headerLink,
				errorLink: errorLink,
				timeStartLink: timeStartLink,
				timeEndLink: timeEndLink,
			};
		}),
		shareReplay(1),
	);

	/**
	 * Observable of apollo client for communicating with abstraction layer.
	 */
	public abstractionLayerClient$ = combineLatest({
		commonConfiguration: this.commonClientConfiguration$,
		launchDarklyFlags: this.launchdarklyService.launchDarklyFlags$,
	}).pipe(
		map(source => {
			// Set uri based on environment from LaunchDarkly flags.
			const launchDarklyEnvironmentUri = source.launchDarklyFlags['staging'] ? environment.abstractionLayerStagingUrl : environment.abstractionLayerReleaseUrl;
			const uriLink = this.httpLink.create({ uri: launchDarklyEnvironmentUri });

			// Return client to subscribers.
			return new ApolloClient({
				link: from([source.commonConfiguration.errorLink, source.commonConfiguration.headerLink, source.commonConfiguration.timeStartLink, source.commonConfiguration.timeEndLink, uriLink]),
				cache: new InMemoryCache(),
			});
		}),
		filter(client => !!client),
		shareReplay(1),
	);

	/**
	 * Observable of apollo client for communicating with abstraction layer.
	 */
	public authLayerClient = combineLatest({
		commonConfiguration: this.commonClientConfiguration$,
		launchDarklyFlags: this.launchdarklyService.launchDarklyFlags$,
	}).pipe(
		map(source => {
			// Set uri based on environment from LaunchDarkly flags.
			const authLayerUri = `${this.appConfigService.getConfig().authLayer_staging}`;
			const uriLink = this.httpLink.create({ uri: authLayerUri });

			// Return client to subscribers.
			return new ApolloClient({
				link: from([source.commonConfiguration.errorLink, source.commonConfiguration.headerLink, source.commonConfiguration.timeStartLink, source.commonConfiguration.timeEndLink, uriLink]),
				cache: new InMemoryCache(),
			});
		}),
		filter(client => !!client),
		shareReplay(1),
	);

	/**
	 * Constructor.
	 * @param httpLink To create httpLink for client.
	 * @param authService To get session ID for authentication.
	 * @param launchdarklyService To get feature flags for current user.
	 * @param loggerService To log result and errors.
	 * @param appConfigService
	 */
	constructor(
		private httpLink: HttpLink,
		private authService: AuthService,
		private launchdarklyService: LaunchDarklyService,
		private loggerService: TmtLoggerService,
		private appConfigService: AppConfigService,
	) {}

	/**
	 * Watches a graphql query and returns an RxJS observable of that query.
	 * Since we use the ApolloClient API directly, rather than the apollo-angular abstraction,
	 * the client will return an ObservableQuery rather than an Observable.
	 * ObservableQuery is not directly compatible with RxJS.
	 * This function wraps watchQuery to convert that returned ObservableQuery to an RxJS Observable.
	 * @param client Client that performs the query.
	 * @param options Query options, as used by regular apollo query function.
	 * @returns An observable of the query result.
	 */
	public observableWatchQuery$(client: ApolloClient<NormalizedCacheObject>, options: WatchQueryOptions) {
		const query = client.watchQuery(options);

		return this.convertApolloObservableToRxJSObservable(query);
	}

	/**
	 * Converts an apollo ObservableQuery into an RxJS Observable.
	 * @param input ObservableQuery to subscribe to as an observable.
	 * @returns An RxJS observable matching the input.
	 */
	private convertApolloObservableToRxJSObservable(input: ObservableQuery<any>): Observable<any> {
		return new Observable(observer => {
			const subscription = input.subscribe({
				next: result => observer.next(result),
				error: error => observer.error(error),
				complete: () => observer.complete(),
			});

			return () => subscription.unsubscribe();
		});
	}
}
