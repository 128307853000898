import { AsyncPipe, DatePipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { catchError, map, Observable, of } from 'rxjs';

import { ReleaseNoteComponent } from 'src/app/components/release-notes/release-note/release-note.component';
import { ReleaseNote } from 'src/app/models/release-note';
import { ReleaseNoteGroup } from 'src/app/models/release-note-group';
import { AuthService } from 'src/app/services/auth.service';
import { ReleaseNotesService } from 'src/app/services/release-notes.service';

/**
 * Displays list of grouped release notes.
 * For now, assumes release notes are "jira" release notes, with title in IssueTitle property.
 */
@Component({
	selector: 'app-grouped-release-notes',
	standalone: true,
	imports: [AsyncPipe, DatePipe, JsonPipe, ReleaseNoteComponent],
	templateUrl: './grouped-release-notes.component.html',
	styleUrl: './grouped-release-notes.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupedReleaseNotesComponent implements OnInit {
	/**
	 * Release notes to display.
	 */
	@Input({ required: true }) releaseNotes$!: Observable<ReleaseNote[]>;

	/**
	 * Whether or not editing should be possible. Will also require user to be admin.
	 */
	@Input() editable = false;

	/**
	 * Grouped release notes to display in template.
	 */
	public groupedReleaseNotes$!: Observable<ReleaseNoteGroup[]>;

	/**
	 * Constructor.
	 * @param releaseNotesService To group and filter release notes.
	 * @param authService To check user signed in status.
	 */
	constructor(
		private releaseNotesService: ReleaseNotesService,
		public authService: AuthService,
	) {}

	/**
	 * On init, filter and group the input release notes.
	 * Assumes input observable does not change.
	 */
	ngOnInit(): void {
		this.groupedReleaseNotes$ = this.releaseNotes$.pipe(
			map(result => {
				const releaseNotes = result.sort((a, b) => new Date(b.ReleaseTime).getTime() - new Date(a.ReleaseTime).getTime());
				return this.releaseNotesService.groupReleaseNotes(releaseNotes);
			}),
			catchError(error => {
				console.error('Error getReleaseNotes:', error);
				return of([]);
			}),
		);
	}
}
