@if ({ underMaintenance: underMaintenance$ | async }; as source) {
	@if (source.underMaintenance === true) {
		<app-under-maintenance></app-under-maintenance>
	} @else if (source.underMaintenance === false) {
		<div class="app-layout">
			<app-header></app-header>

			<main class="app-main bg" style="box-sizing: border-box">
				<router-outlet></router-outlet>
			</main>
		</div>
	}
}
