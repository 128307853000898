import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { map, tap } from 'rxjs';

import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { LoginService } from './login.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	standalone: true,
	imports: [ProgressSpinnerModule],
	providers: [LoginService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
	/**
	 * Observable of origin.
	 * Gets origin from query params if available, then saves to local storage,
	 * which is used as backup after navigation.
	 */
	private origin$ = this.activatedRoute.queryParamMap.pipe(
		map(queryParams => queryParams.get('origin')),
		tap(origin => {
			if (origin) {
				localStorage.setItem('origin', origin);
			}
		}),
		map(origin => (origin ? origin : (localStorage.getItem('origin') ?? ''))),
		map(origin => {
			// Ensure that orgin parameters that may be incorrect are removed from the origin URL.
			if (origin && origin.toLowerCase() !== 'windows') {
				const url = new URL(origin);
				url.searchParams.delete('sessionId');
				url.searchParams.delete('username');
				url.searchParams.delete('company');
				return url.toString();
			} else {
				return origin;
			}
		}),
	);

	/**
	 * Observable of auth code.
	 */
	private authCode$ = this.activatedRoute.queryParamMap.pipe(map(queryParams => queryParams.get('code') ?? ''));

	/**
	 * Constructor.
	 * @param loginService To subscribe to user session observable.
	 * @param activatedRoute To observe query parameters.
	 */
	constructor(
		private loginService: LoginService,
		private activatedRoute: ActivatedRoute,
	) {}

	/**
	 * On init, subscribe to user session observable to handle user session creation/verification.
	 */
	ngOnInit() {
		this.loginService.handleUserSession$(this.origin$, this.authCode$).subscribe();
	}
}
