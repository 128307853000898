import { gql } from 'apollo-angular';

export class loginQueries {
	public static getUserDetails = gql`
		query getUserDetails($sessionId: String!) {
			getUserDetails(sessionId: $sessionId) {
				SessionUID
				Username
				Company
				Email
				IsAuthenticated
				IdToken
			}
		}
	`;

	public static validateSessionId = gql`
		query validateSessionId($sessionId: String!) {
			validateSessionId(sessionId: $sessionId)
		}
	`;

	public static ping = gql`
		query ping {
			ping
		}
	`;
}
