import { Component } from '@angular/core';

import { CardModule } from 'primeng/card';

/**
 * Component used to display a message when application is under maintenance.
 */
@Component({
	selector: 'app-under-maintenance',
	standalone: true,
	imports: [CardModule],
	templateUrl: './under-maintenance.component.html',
	styleUrl: './under-maintenance.component.scss',
})
export class UnderMaintenanceComponent {}
